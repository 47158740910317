// Generated by Framer (6807895)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/XdYKavQM_";

const enabledGestures = {Y8CmpsZnR: {hover: true, pressed: true}};

const serializationHash = "framer-5nsRi"

const variantClassNames = {Y8CmpsZnR: "framer-v-1id2dk6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, GS2z_ymeB: link ?? props.GS2z_ymeB, uXGoZpA4g: title ?? props.uXGoZpA4g ?? "Dribbble"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, uXGoZpA4g, GS2z_ymeB, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Y8CmpsZnR", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={GS2z_ymeB} nodeId={"Y8CmpsZnR"} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-1id2dk6", className, classNames)} framer-115b96t`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Y8CmpsZnR"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-c9289abc-7dec-4c99-98f4-fb1fd2262365, rgb(246, 246, 246))", borderBottomLeftRadius: 36, borderBottomRightRadius: 36, borderTopLeftRadius: 36, borderTopRightRadius: 36, ...style}} variants={{"Y8CmpsZnR-hover": {backgroundColor: "var(--token-79762126-b16b-4fb9-9dc0-b2321cf95106, rgb(240, 240, 240))"}, "Y8CmpsZnR-pressed": {backgroundColor: "var(--token-79762126-b16b-4fb9-9dc0-b2321cf95106, rgb(240, 240, 240))"}}} {...addPropertyOverrides({"Y8CmpsZnR-hover": {"data-framer-name": undefined}, "Y8CmpsZnR-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-18zh1ui"} data-styles-preset={"XdYKavQM_"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-1b444440-6e98-401c-93cc-651f6c08f890, rgb(20, 20, 31)))"}}>Dribbble</motion.p></React.Fragment>} className={"framer-5rs5w5"} data-framer-name={"label"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"mMDnoWJIY"} style={{"--extracted-r6o4lv": "var(--token-1b444440-6e98-401c-93cc-651f6c08f890, rgb(20, 20, 31))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={uXGoZpA4g} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5nsRi.framer-115b96t, .framer-5nsRi .framer-115b96t { display: block; }", ".framer-5nsRi.framer-1id2dk6 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 8px 20px 8px 20px; position: relative; text-decoration: none; width: min-content; }", ".framer-5nsRi .framer-5rs5w5 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5nsRi.framer-1id2dk6 { gap: 0px; } .framer-5nsRi.framer-1id2dk6 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-5nsRi.framer-1id2dk6 > :first-child { margin-left: 0px; } .framer-5nsRi.framer-1id2dk6 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 103.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"fB12ZqkHv":{"layout":["auto","auto"]},"eVFe0suYB":{"layout":["auto","auto"]}}}
 * @framerVariables {"uXGoZpA4g":"title","GS2z_ymeB":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramergI7NANGks: React.ComponentType<Props> = withCSS(Component, css, "framer-5nsRi") as typeof Component;
export default FramergI7NANGks;

FramergI7NANGks.displayName = "chip-grey";

FramergI7NANGks.defaultProps = {height: 40, width: 103.5};

addPropertyControls(FramergI7NANGks, {uXGoZpA4g: {defaultValue: "Dribbble", displayTextArea: false, title: "Title", type: ControlType.String}, GS2z_ymeB: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramergI7NANGks, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})